import { isNull, isUndefined } from 'lodash';
import isString from 'lodash/isString';

export function stringOrNull(element: any): string | null {
  if (typeof element === 'string') {
    return element;
  } else {
    return null;
  }
}

export function nonEmptyStringOrNull(element: any): string | null {
  const res = stringOrNull(element);
  return res?.trim() === '' ? null : res;
}

export function nonEmptyStringOrUndefined(element: any): string | undefined {
  const res = stringOrNull(element);
  return res === null || res.trim() === '' ? undefined : res;
}

export function nonEmptyStringOrElse<T>(element: any, elseValue: T): string | T {
  const res = stringOrNull(element);
  return res === null || res.trim() === '' ? elseValue : res;
}

export function isDefined<T>(value: T | undefined | null): value is T {
  return typeof value !== 'undefined' && value !== null;
}

type IfDefinedInput = object | number | string | boolean;

/** If a value is defined, then pass value to provided function. If not, return either undefined or null, whichever the value is */
export function ifDefined<T extends IfDefinedInput | null, S>(value: T, func: (v: NonNullable<T>) => S): T extends null ? null : S;
export function ifDefined<T extends IfDefinedInput | undefined, S>(
  value: T,
  func: (v: NonNullable<T>) => S
): T extends undefined ? undefined : S;
export function ifDefined<S>(value: unknown, func: (v: unknown) => S): S | null | undefined {
  if (isUndefined(value)) return undefined;

  if (isNull(value)) return null;

  return func(value);
}

export function isTruthy<T>(value: T | undefined | null | false | '' | 0): value is T {
  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  return !!value;
}

/**
 * @deprecated, use native js pattern of `value ?? or_else`
 */
export function definedOrElse<T, S>(value: T | undefined | null, elseValue: S): T | S {
  if (isDefined(value)) {
    return value;
  } else {
    return elseValue;
  }
}

export function someDefined<T>(...args: Array<T | undefined | null>): boolean {
  return args.some(isDefined);
}

export function isRelativeUrl(url: string): boolean {
  return !/^(http|ftp)s?:\/\/|^\/\//i.test(url) && url !== '';
}

export function isAlphanumeric(str: string): boolean {
  return /^([A-Za-z]|[0-9]| )+$/.test(str) && str !== '';
}

export function isNonEmptyString(value: any): value is string {
  return isString(value) && nonEmptyStringOrNull(value) !== null;
}

export function isNonEmptyArray(value: any): value is any[] {
  return Array.isArray(value) && value.length > 0;
}

export function booleanOrFalse(element: any): boolean {
  if (typeof element === 'boolean') {
    return element;
  } else {
    return false;
  }
}

export function booleanOrTrue(element: any): boolean {
  if (typeof element === 'boolean') {
    return element;
  } else {
    return true;
  }
}

export function dateOrParse(value: string | Date) {
  if (value instanceof Date) {
    return value;
  } else {
    return Date.parse(value);
  }
}

// Transform value to: "FIRSTNAME LASTNAME" format
export function formatFullNameForSignature(value: any): string | undefined {
  return value?.replace(/\s+/g, ' ').trim().toUpperCase();
}

export type Optional<T, K extends keyof T> = Pick<Partial<T>, K> & Omit<T, K>;

/** Given two strings of semantic versions (ex: '1.0.2' and '1.0.3'), return the higher version */
export function findHighestSemanticVersion(a: string, b: string) {
  return a.split('.') < b.split('.') ? b : a;
}
