import App from 'next/app';
import { QueryClient, QueryClientProvider } from 'react-query';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import posthog from 'posthog-js';

import 'global-styles.css';

// Radix Themes
// @see {@link https://www.radix-ui.com/themes/docs/theme/token-reference#modifying-tokens}
import '@radix-ui/themes/styles.css';
import 'theme.config.css';

import { PosthogEventName } from '@lib/posthog/types';

import { GoogleTagManagerScript } from 'components/other/GoogleTagManager';
import TickTockPixelScript from 'components/other/TicktokPixel';
import { trpc } from 'components/queries/trpc';
import env from 'util/env';
import isBrowser from 'util/isBrowser';
import { AppPropsWithLayout } from 'util/nextPageWithLayout';
import { isDefined } from 'util/parseUtils';

import MainLayout from '../components/layouts/main-layout';

import PostHogProvider from './provider';

/**
 * Connect RUM and APM (traces): {@link https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum}
 */
function initDatadogAnalytics() {
  datadogRum.init({
    clientToken: 'pub028fdadb9ecf9b1512662b0e985a07f6',
    applicationId: 'fa949d1e-1f85-44a2-a35b-a05263e5cae4',
    sessionSampleRate: 100,
    silentMultipleInit: true,
    service: 'levels-web',
    allowedTracingUrls: ['https://app.levelshealth.com/api'],
  });
}

/**
 * Initialize Datadog's browser log collection for frontend logging
 */

function initDatadogBrowserLogsCollection() {
  datadogLogs.init({
    clientToken: 'pub7c8ae488fbe34026a193d3469c7dd604',
    sessionSampleRate: 100,
    silentMultipleInit: true,
  });
}

if (isBrowser) {
  if (env.isProdNode) {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');
    const partnerCode = urlParams.get('partner');

    if (isDefined(partnerCode) && isDefined(email)) {
      posthog.capture(PosthogEventName.enum.EMAIL_CAPTURED, { from: 'partner-page', $set: { email } });
    }

    // Datadog
    initDatadogAnalytics();
    initDatadogBrowserLogsCollection();
  }
}

const queryClient = new QueryClient();

class LevelsApp extends App<AppPropsWithLayout> {
  static async getInitialProps({ Component, ctx }) {
    let pageProps = {};

    if (isDefined(Component.getInitialProps)) {
      pageProps = await Component.getInitialProps(ctx);
    }

    return { pageProps };
  }

  render() {
    const { Component, pageProps } = this.props;

    if (isDefined(Component?.getLayout)) {
      return (
        <>
          <GoogleTagManagerScript scriptId="google-tag-manager-root" />
          <TickTockPixelScript scriptId="ticktok-pixel" />
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <PostHogProvider>{Component.getLayout(<Component {...pageProps} />)}</PostHogProvider>
          </QueryClientProvider>
        </>
      );
    }

    return (
      <>
        <GoogleTagManagerScript scriptId="google-tag-manager-root" />
        <TickTockPixelScript scriptId="ticktok-pixel" />
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <PostHogProvider>
            <MainLayout>
              <Component {...pageProps} />
            </MainLayout>
          </PostHogProvider>
        </QueryClientProvider>
      </>
    );
  }
}

export default trpc.withTRPC(LevelsApp);
