import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';
import authRefreshInterceptor from '../../../util/authRefreshInterceptor';
import { exposeField } from '../../../util/client';
import { clock } from '../../../util/dates';
import { noop } from '../../../util/miscUtils';
import { isDefined, stringOrNull } from '../../../util/parseUtils';

/* Handles client user sessions on Cognito.
 * Currently, we set an "Authorization" cookie session (see server/actions/user/session.ts),
 * but this cookie is only valid for one day.
 *
 * 1. Page loads. Compute how much time we have left.
 * 2. If less than 5 minutes, refresh now. Otherwise, schedule a refresh in timeLeft-5 minutes.
 * 3. Every time a refresh completes, schedule a new one in a day minus 5 minutes.
 * 4. If a log out happens, cancel all refreshes.
 * 5. If a log in happens, schedule a refresh in a day minus 5 minutes.
 */

const remoteServer = process.browser ? undefined : 'http://localhost:3000';
const refreshDuration = 1000 * 60 * 60 * 24; // 1 day, in ms

// When https://github.com/axios/axios/pull/2040/files is fixed, we can clone apiClient directly.
// This is used when the config needs to be modified (like adding headers).
export function newApiClient() {
  return axios.create({
    // Any config included in all requests.
    // Don't include anything mutable.
    baseURL: remoteServer,
  });
}

const apiClient = newApiClient();

const storage = typeof window !== 'undefined' && isDefined(window['localStorage']) ? window.localStorage : null;

export const getUserId = (): string | undefined => storage?.getItem('user_id') ?? undefined;
const getRefreshToken = () => storage?.getItem('refresh_token') ?? null;
const getLastUpdatedAuthTokenAt = () => parseInt(localStorage?.getItem('auth_token_updated_at') ?? '0');

set3PUserId(getUserId() ?? undefined);

function updateLocalRefreshToken(newUserId: string | undefined, newRefreshToken: string | undefined) {
  if (isDefined(storage)) {
    if (isDefined(newRefreshToken) && isDefined(newUserId)) {
      storage.setItem('refresh_token', newRefreshToken);
      storage.setItem('user_id', newUserId);
      set3PUserId(newUserId);
    } else {
      storage.removeItem('refresh_token');
      storage.removeItem('user_id');
      storage.removeItem('auth_token_updated_at');
      set3PUserId(undefined);
    }
  }
}

function updateLocalAuthTokenTime(isSet: boolean) {
  if (isDefined(storage)) {
    if (isSet) {
      const time = clock.nowMilliseconds;
      storage.setItem('auth_token_updated_at', time.toString());
    } else {
      storage.removeItem('auth_token_updated_at');
    }
  }
}

export function refreshRemoteAuthToken(failedRequest?: any) {
  if (!isDefined(storage)) {
    return Promise.reject(new Error('not on client'));
  }
  if (isDefined(failedRequest)) {
    console.warn('failed request', failedRequest);
  }
  const userId = getUserId();
  const refreshToken = getRefreshToken();
  if (isDefined(refreshToken) && isDefined(userId)) {
    return apiClient
      .post('/api/user/login', { refresh: refreshToken, userId: userId })
      .then(tokenRefreshResponse => {
        if (tokenRefreshResponse.status === 200) {
          return Promise.resolve({ postLoginUrl: stringOrNull(tokenRefreshResponse.data?.postLoginUrl) ?? null });
        } else {
          return Promise.reject(new Error('refresh failed'));
        }
      })
      .catch(err => {
        console.warn('[refreshRemoteAuthToken] Error', err);
        return Promise.reject(err);
      });
  } else {
    return Promise.reject(new Error('no refresh token'));
  }
}

export function minutesUntilAuthTokenExpiry() {
  const now = clock.nowMilliseconds;
  const lastUpdatedAuthTime = getLastUpdatedAuthTokenAt();
  const refreshAgoMinutes = (now - lastUpdatedAuthTime) / 1000 / 60;
  return Math.max(0, 60 * 24 - refreshAgoMinutes);
}

let timeout: number | undefined;
export function updateAuthTokenWithRefreshToken(): Promise<{ success: boolean; postLoginUrl?: string }> {
  if (isDefined(storage) && isDefined(getRefreshToken())) {
    const minutesUntilExpiry = minutesUntilAuthTokenExpiry();
    if (isDefined(timeout)) {
      clearTimeout(timeout);
    }
    if (minutesUntilExpiry < 6) {
      // refresh immediately
      return refreshRemoteAuthToken()
        .then(resp => {
          console.log('[updateAuthTokenWithRefreshToken] Refresh!', minutesUntilExpiry);
          timeout = window.setTimeout(() => {
            updateAuthTokenWithRefreshToken().finally(noop);
          }, refreshDuration - 1000 * 60 * 5); // 1 day minus 5 minutes
          updateLocalAuthTokenTime(true);
          return { success: true, postLoginUrl: resp?.postLoginUrl ?? undefined };
        })
        .catch(err => {
          console.warn('[updateAuthTokenWithRefreshToken] Failed refresh', err?.data);
          updateLocalAuthTokenTime(false);
          //timeout = window.setTimeout(updateAuthTokenWithRefreshToken, 1000*60*5); // attempt again?
          return { success: false };
        });
    } else {
      timeout = window.setTimeout(() => {
        updateAuthTokenWithRefreshToken().finally(noop);
      }, 1000 * 60 * (minutesUntilExpiry - 5));
    }
  }
  return Promise.resolve({ success: false });
}

exposeField('refreshAuth', () => {
  updateAuthTokenWithRefreshToken().finally(noop);
});

const loginCallbacks: Set<Function> = new Set();
/**
 * addLoginCallback
 *
 * Allows code to register a listener which will fire
 * after a successful login completes.
 *
 * There is currently no removeLoginCallback implementation
 * because we have no use case for it yet #yagni
 */
export function addLoginCallback(listener: Function) {
  loginCallbacks.add(listener);
}

export function logInWithRefreshToken(newUserId: string, newRefreshToken: string, alreadyFresh: boolean) {
  if (alreadyFresh) {
    updateLocalAuthTokenTime(true);
  }
  updateLocalRefreshToken(newUserId, newRefreshToken);
  updateAuthTokenWithRefreshToken().finally(noop);
  // If any code was subscribing for login changes, let it know
  for (const callback of loginCallbacks) {
    callback();
  }
}

export function logOutClearingRefreshToken() {
  updateLocalRefreshToken(undefined, undefined);
  updateLocalAuthTokenTime(false);
}

function set3PUserId(userId: string | undefined) {
  if (isDefined(storage)) {
    // only runs on clients
    datadogRum.addRumGlobalContext('userId', userId);
    if (isDefined(window?.['Beacon'])) {
      if (isDefined(userId)) {
        window?.['Beacon']?.('identify', {
          userId,
        });
      } else {
        window?.['Beacon']?.('logout');
      }
    }
  }
}

if (isDefined(storage)) {
  authRefreshInterceptor(apiClient, refreshRemoteAuthToken); // Side effects into `client`
  updateAuthTokenWithRefreshToken().finally(noop); // Queues refresh timeout
}

export default apiClient;
