import cx from 'classnames';
import { LegacyRef } from 'react';
import { isTruthy } from 'util/parseUtils';

export interface CardLayoutProps {
  children: React.ReactNode;
  /** Pass in custom styles using Tailwind */
  className?: string;
  /** Customize the background color of the CardLayout */
  color?: 'default' | 'red' | 'green' | 'transparent-mobile' | 'gray' | 'black' | 'white' | 'transparent' | 'dark-green';
  label?: string;
  /** Custom padding */
  padding?: 'default' | 'none' | 'small' | 'only-for-web' | 'middle';
  forwardRef?: LegacyRef<HTMLDivElement>;
}

/**
 * Renders a block which is a base container for other elements.
 * This element is currently used in the redesigned member portal.
 */
export function CardLayout({ children, className, color = 'default', label, padding = 'default', forwardRef }: CardLayoutProps) {
  return (
    <div
      ref={forwardRef ?? null}
      className={cx(
        'rounded-lg',
        'relative',
        {
          'p-0': padding === 'none',
          'lg:p-8 p-6': padding === 'default',
          'py-4 px-7': padding === 'small',
          'lg:p-8 p-0': padding === 'only-for-web',
          'p-6': padding === 'middle',
          'bg-light-green': color === 'green',
          'bg-light-gray-background': color === 'gray',
          'bg-black': color === 'black',
          'bg-gray-background': color === 'default',
          'bg-dark-red': color === 'red',
          'bg-white': color === 'white',
          'bg-transparent lg:bg-gray-background': color === 'transparent-mobile',
          'bg-transparent': color === 'transparent',
          'bg-btn-green-bg': color === 'dark-green',
          'mt-10': isTruthy(label),
        },
        className
      )}
    >
      {isTruthy(label) && <h3 className="text-[24px] mb-6 absolute top-[-48px] left-0">{label}</h3>}
      {children}
    </div>
  );
}
