import assert from 'assert';
import {
  addDays,
  addMinutes,
  differenceInYears,
  eachDayOfInterval,
  format as dateFormat,
  isSameDay,
  parse,
  startOfDay,
  subDays,
} from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import max from 'lodash/max';
import moment from 'moment-timezone';
import { isDefined } from './parseUtils';

export interface TimezoneHistory {
  currentTimezone: string;
  history: [Date, string][];
}

export function timezoneAsOf(asOfWhen: Date | 'now', tzHistory: TimezoneHistory): string {
  if (asOfWhen === 'now') {
    return tzHistory.currentTimezone;
  } else {
    const beforeAsOfWhenTz = tzHistory.history.find(r => r[0] < asOfWhen)?.[1] ?? tzHistory.history[0]?.[1]; // newest history before asOfWhen // first history item
    return beforeAsOfWhenTz ?? tzHistory.currentTimezone;
  }
}

export const clock = {
  get now() {
    // Using this makes it easy to mock now() if we ever need to in tests.
    return new Date();
  },
  get nowMilliseconds() {
    return clock.now.getTime();
  },
  get nowSeconds() {
    return Math.floor(clock.now.getTime() / 1000);
  },

  nextMidnight(timezone: string, time?: Date) {
    time = time ?? clock.now;
    const midnight = this.mostRecentMidnight(timezone, time);

    // This helps us deal with dates on the other side of the international date line (eg. Australia)
    const daysToAdd = isSameDay(time, midnight) ? 1 : 2;
    return addDays(midnight, daysToAdd);
  },

  // Convenience method to get midnight for a timezone. This is easy to mess up.
  // International dateline safe
  // userTz is in the tz database format. See https://en.wikipedia.org/wiki/List_of_tz_database_time_zones.
  mostRecentMidnight(timezone: string, time?: Date) {
    time = time ?? clock.now;
    const midnight = zonedTimeToUtc(startOfDay(time), timezone);

    // This helps us deal with dates on the other side of the international date line (eg. Australia)
    const daysToAdd = isSameDay(time, midnight) ? 0 : 1;
    const adjustedMidnight = addDays(midnight, daysToAdd);

    // oddly sometimes the "mostRecentMidnight" is in the future of `time` which is... obviously wrong
    // so we adjust back if that is true
    // This is super hacky but timezone is complicated :/
    return adjustedMidnight.getTime() > time.getTime() ? addDays(adjustedMidnight, -1) : adjustedMidnight;
  },

  format(time: Date | number, format: string, timezone: string) {
    const adjusted = utcToZonedTime(time, timezone);
    return dateFormat(adjusted, format);
  },
};

/**
 * Re-usable formatters so you don't need to get creative. To be used with date-dns `format`.
 */

// Ex: 2020-12-01
const iso8601Date = 'yyyy-MM-dd';

// Ex: 2017-06-16T19:59:11
const iso8601DateTime = `yyyy-MM-dd'T'HH:mm:ss`;

// Ex: April 5th, 2020
const friendlyAmericanDateWithOrdinal = 'MMMM do, yyyy';

// Ex: 12/30/23
const shortDate = 'MM/dd/yy';

// Ex: April 05, 2020 06:29:30 PM
const friendlyAmericanDateTime = 'MMMM dd, yyyy hh:mm:ss aa';

// Ex: April
const longMonthName = 'MMMM';

// Ex: 08:29:30 PM
const hmsAmPmTime = 'hh:mm:ss aa';

// Ex: 8:29 PM
const shortAmPmTime = 'h:mm aa';

// Ex: Tuesday, January 1, 2020
const longformAmericanDate = 'EEEE, MMMM d, yyyy';

const isoYearWeek = 'RR-II';

export const dateFormats = {
  iso8601Date,
  iso8601DateTime,
  isoYearWeek,
  friendlyAmericanDateWithOrdinal,
  friendlyAmericanDateTime,
  shortDate,
  hmsAmPmTime,
  shortAmPmTime,
  longformAmericanDate,
  longMonthName,
};

// Calendar date from user's perspective
export class ClientDate {
  year: number;
  month: number;
  day: number;

  public constructor(year: number, month: number, day: number) {
    this.year = year;
    this.month = month;
    this.day = day;
  }

  public valueOf() {
    return this.toString();
  }

  static fromUtcTimestamp(utcTimestamp: Date, userTz: string | TimezoneHistory): ClientDate {
    const date = startOfDay(utcToZonedTime(utcTimestamp, typeof userTz === 'string' ? userTz : timezoneAsOf(utcTimestamp, userTz)));
    return ClientDate.fromDate(date);
  }

  static today(userTz: string): ClientDate {
    return ClientDate.fromUtcTimestamp(clock.now, userTz);
  }

  // convert from date with no timezone conversion
  static fromDate(date: Date): ClientDate {
    return new ClientDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
  }

  // convert to date with no timezone conversion
  public toDate(): Date {
    return new Date(this.year, this.month - 1, this.day);
  }

  public toUtcTimestamp(userTz: string): Date {
    return zonedTimeToUtc(this.toDate(), userTz);
  }

  static fromString(value: string): ClientDate {
    const dateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}$/;
    const parsed = new Date(value);

    if (!dateRegex.test(value) || isNaN(parsed.getTime())) {
      throw new Error(`Expect be a string like "2020-10-01"`);
    }

    return ClientDate.fromDate(parsed);
  }

  /**
   * @summary Returns a time interval in the format h:mm-h:mm aa
   * @param {Date} startAt
   * @param {Date} endAt
   * @param {string} userTz
   * @returns {string} the formatted interval e.g. 8:00-10:00am
   */

  static constructTimeWindow(startAt: Date, endAt: Date, userTz: string): string {
    const formatTime = (date: Date, format: string) => dateFormat(utcToZonedTime(date, userTz), format);
    return `${formatTime(startAt, 'h:mm')}-${formatTime(endAt, dateFormats.shortAmPmTime)}`;
  }

  public toString(): string {
    return `${this.year}-${String(this.month).padStart(2, '0')}-${String(this.day).padStart(2, '0')}`;
  }

  public format(format: string): string {
    return dateFormat(this.toDate(), format);
  }

  public subDays(days: number): ClientDate {
    return ClientDate.fromDate(subDays(this.toDate(), days));
  }

  public addDays(days: number): ClientDate {
    return ClientDate.fromDate(addDays(this.toDate(), days));
  }

  /**
   * Compares the date to another `ClientDate` for equality
   *
   * @param clientDate The date to compare
   * @returns whether the two dates are equal
   */
  public equals(clientDate: ClientDate): boolean {
    return this.day === clientDate.day && this.month === clientDate.month && this.year === clientDate.year;
  }
}

/**
 * group a list of values into each calendar days between startDate and endDate (inclusive)
 * @param allValues
 * @param startDate
 * @param endDate
 * @param userTz
 * @param timestampFunc: function that takes an item from the list and returns the timestamp to that is used to group the item
 * @param endTimestampFunc: if provided, an item may show up in multiple days if its [timestamp, endTimestamp] overlaps multiple calendar days
 */
export function groupByDay<T>(
  allValues: T[],
  startDate: ClientDate,
  endDate: ClientDate,
  userTz: string,
  timestampFunc: (val: T) => Date,
  endTimestampFunc?: (val: T) => Date | null | undefined
): { clientDate: ClientDate; values: T[] }[] {
  try {
    const days = (
      startDate.toDate() === endDate.toDate()
        ? [startDate.toDate()]
        : eachDayOfInterval({ start: startDate.toDate(), end: endDate.toDate() })
    ).map(ClientDate.fromDate);
    const groups: Record<string, T[]> = Object.fromEntries(days.map(d => [d.toString(), []]));
    const addToGroups = (date: ClientDate, val: T) => {
      const key = date.toString();
      if (isDefined(groups[key])) {
        groups[key].push(val);
      }
    };

    allValues.forEach(val => {
      const start = timestampFunc(val);
      const end = isDefined(endTimestampFunc) && isDefined(endTimestampFunc(val)) ? endTimestampFunc(val) : null;

      if (!isDefined(end) || start === end) {
        addToGroups(ClientDate.fromUtcTimestamp(start, userTz), val);
      } else {
        const daysBetween = eachDayOfInterval({
          start: ClientDate.fromUtcTimestamp(start, userTz).toDate(),
          end: ClientDate.fromUtcTimestamp(end, userTz).toDate(),
        });
        daysBetween.forEach(day => addToGroups(ClientDate.fromDate(day), val));
      }
    });

    return Object.entries(groups).map(([date, values]) => ({ clientDate: ClientDate.fromString(date), values }));
  } catch (error) {
    // TODO: just here for temp debugging
    console.log('groupByDay failed', { error, startDate, endDate, start: startDate.toDate(), end: endDate.toDate() });
    throw error;
  }
}

export function timestampRangeFromDateRange(startDate: ClientDate, endDate: ClientDate, userTz: string): [Date, Date] {
  // TODO: use full timezone history to be accurate

  // endDate is inclusive, so we need to get the entire day (but subtract 1 millisecond so it doesn't spill over to the next day)
  const endTime = new Date(addDays(endDate.toUtcTimestamp(userTz), 1).getTime() - 1);

  return [startDate.toUtcTimestamp(userTz), endTime];
}

export function isValidTimezone(timezone: string): boolean {
  try {
    return isDefined(Intl.DateTimeFormat('en-US', { timeZone: timezone }).resolvedOptions().timeZone);
  } catch (e) {
    return false;
  }
}

/** Validates the given birth-date (given as a string) indicates the visitor is at least 18 years old */
export const validateBirthDate = (value: string) => {
  const birthDate = new Date(value);
  const diffInYears = differenceInYears(clock.now, birthDate);
  return diffInYears >= 18;
};

/**
 * Takes an input string and converts it into a Date using the provided format for the input string.
 *
 * @param value the string value to be parsed into a date
 * @param valueFormatString the format string for the value being provided
 * @returns a date created out of the parsed string using the provided format
 */
export function parsedDateFromString(value: string, valueFormatString: string): Date {
  return parse(value, valueFormatString, new Date());
}

/**
 * Parse the date from the server without converting to user local time zone
 *
 * @param value date to convert
 * @returns a date in UTC timezone
 * */
export function convertLocalDateToUTC(value: string | number | Date): Date {
  const dateOptions = { timeZone: 'UTC' };
  const dateFormatter = new Intl.DateTimeFormat('en-US', dateOptions);

  return new Date(dateFormatter.format(new Date(value)));
}

/**
 * Takes an input string and format for the input string and formats it as an ISO 8601 date.
 *
 * @param value the string value to be formatted
 * @param valueFormatString the format string for the string value provided
 * @returns a date formatted in the ISO 8601 format
 */
export function formattedIsoDateString(value: string, valueFormatString: string): string {
  const parsed = parsedDateFromString(value, valueFormatString);
  return dateFormat(parsed, dateFormats.iso8601Date);
}

const DATE_TIME_WITH_OPTIONAL_UTC_OFFSET_REGEX =
  /^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):?([0-5][0-9])?(\.[0-9]+)?(Z|[+-](?:2[0-3]|[01][0-9]):[0-5][0-9])?$/;

export function isISODateString(dateString: string) {
  return DATE_TIME_WITH_OPTIONAL_UTC_OFFSET_REGEX.exec(dateString);
}

/**
 * Convert an ISO 8601 formatted string into a Date object that is adjusted with the UTC offset.
 *
 * @param dateString the ISO 8601 formatted string
 * @returns Date object that is adjusted with the UTC offset
 */
export function getDateFromISOStringAdjustWithUTCOffset(dateString: string): Date {
  if (!isISODateString(dateString)) {
    throw new Error(`Invalid ISO string: "${dateString}"`);
  }
  const utcOffset = moment.parseZone(dateString).utcOffset();
  const utcDate = new Date(dateString);
  const offsetDate = addMinutes(utcDate, utcOffset);
  return offsetDate;
}

/** Given one or more dates, returns the maximum date object */
export function maxDate(date: Date, ...dates: Date[]): Date {
  const maxFromArray = max([date, ...dates]);
  assert(isDefined(maxFromArray), 'max should never be null here');

  return maxFromArray;
}
