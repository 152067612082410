import { noop } from '@lib/util';
import { QueryCache } from '@tanstack/query-core';
import { createTRPCProxyClient, httpBatchLink, TRPCClientError } from '@trpc/client';
import { createTRPCNext } from '@trpc/next';
import Router from 'next/router';
import { TrpcRouter } from 'server/trpc/root-router';
import superjson from 'superjson';
import { isDefined } from 'util/parseUtils';
import { refreshRemoteAuthToken } from '../services/api/apiClient';

/** Vanilla TRPC client, if you don't want hooks */
export const trpcClient = createTRPCProxyClient<TrpcRouter>({
  links: [
    httpBatchLink({
      url: isDefined(process.env.NEXT_PUBLIC_APP_URL) ? `${process.env.NEXT_PUBLIC_APP_URL}/api/trpc` : 'http://localhost:3000/api/trpc',
    }),
  ],
  transformer: superjson,
});

export const trpc = createTRPCNext<TrpcRouter>({
  config() {
    return {
      queryClientConfig: {
        queryCache: new QueryCache({
          async onError(error) {
            if (error instanceof TRPCClientError && error.data?.code === 'UNAUTHORIZED') {
              try {
                await refreshRemoteAuthToken();
              } catch {
                Router.push('/home/login').finally(noop);
              }
            }
          },
        }),
      },
      links: [
        httpBatchLink({
          /**
           * If you want to use SSR, you need to use the server's full URL
           * @link https://trpc.io/docs/ssr
           **/
          url: isDefined(process.env.NEXT_PUBLIC_APP_URL)
            ? `${process.env.NEXT_PUBLIC_APP_URL}/api/trpc`
            : 'http://localhost:3000/api/trpc',
        }),
      ],
      transformer: superjson,
    };
  },
  /**
   * @link https://trpc.io/docs/ssr
   **/
  ssr: false,
});
