import { z } from 'zod';

/**
 * Our naming convention for posthog events is based on https://segment.com/academy/collecting-data/naming-conventions-for-clean-data/
 *
 * To summarize each event should be composed of an object eg: User, Cart, Order, etc. and a action eg: Created, Updated, Deleted, etc.
 *
 * Rules:
 *
 * 1. Use past tense for action
 * 2. Use UPPERCASE_SNAKE_CASE for the enum key
 * 3. Use Proper Case for the enum string value
 * 4. Organize the enum keys in alphabetical order
 */
enum POSTHOG_EVENT_NAME {
  CONTENT_FAVORITED = 'Content Favorited',
  CONTENT_SEEN = 'Content Seen',
  CONTENT_UNFAVORITED = 'Content Unfavorited',
  EMAIL_CAPTURED = 'Email Captured',
  EMAIL_SEND = 'Email Send',
  EXISTING_USER_G7_TRANSFER = 'User Completed G7 Transfer',
  FIRST_GLUCOSE_IMPORTED = 'First Glucose Imported',
  GLUCOSE_ANOMALY_DISMISSED = 'Glucose Anomaly Dismissed',
  HEALTH_LOG_CREATED = 'Health Log Created',
  INVOICE_PAID = 'Invoice Paid',
  MEMBERSHIP_CREATED = 'Membership Created',
  MEMBERSHIP_EXPIRED = 'Membership Expired',
  MEMBERSHIP_SET_TO_AUTO_RENEW = 'Membership Set To Auto Renew',
  MEMBERSHIP_SET_TO_NOT_AUTO_RENEW = 'Membership Set To Not Auto Renew',
  ORDER_CREATED = 'Order Created',
  ORDER_ITEM_CREATED = 'Order Item Created',
  REFUND_CREATED = 'Refund Created',
  SLEEP_IMPORTED = 'Sleep Imported',
  SUBSCRIPTION_CANCELED = 'Subscription Canceled',
  SUBSCRIPTION_CREATED = 'Subscription Created',
  USER_CREATED = 'User Created',
  FREEMIUM_USER_CREATED = 'Freemium User Created',
  USER_DEMOGRAPHICS_SET = 'User Demographics Set',
  USER_FEED_CLICKED = 'User Feed Clicked',
  USER_LOGIN = 'User Login',
  HABIT_LOOP_COMPLETED = 'Targets - Target Completed',
}
export const PosthogEventName = z.nativeEnum(POSTHOG_EVENT_NAME);
export type PosthogEventName = z.infer<typeof PosthogEventName>;

export type PosthogAllowedPropertyValues = string | number | boolean | Date | null | undefined;
export type PosthogEventProperties = Record<string, PosthogAllowedPropertyValues | Record<string, PosthogAllowedPropertyValues>>;

enum POSTHOG_FEATURE_FLAG_NAME {
  DEXCOM_STREAMING_API_ICF_EXEMPT = 'web-dexcom-streaming-api-icf-exempt',
  ENABLE_GLUCOSE_IMPORT_FROM_HEALTHKIT_GOOGLE_FIT = 'mobile_enable_glucose_import_from_healthkit_google_fit',
  ENABLE_LIBRE3_PURCHASE = 'web-enable-libre-3-purchase',
  GLUCOSE_PREDICTIONS_V0 = 'web-glucose-predictions-v0',
  HOMEPAGE_SIGNUP_QUIZ = 'signup-quiz',
  ORDER_BY_PHONE = 'order-by-phone',
  SINGLE_PAGE_SIGNUP = 'single-page-signup',
  TAGS_FOR_MACROS = 'web-tags-for-macros',
  TITLE_FOR_MACROS = 'web-title-for-macros',
}

/**
 * The keys of the backend feature flags that are defined in Posthog.
 * All of the active feature flags should be defined in Posthog Local and Posthog Prod
 */
export const PosthogFeatureFlagName = z.nativeEnum(POSTHOG_FEATURE_FLAG_NAME);
export type PosthogFeatureFlagName = z.infer<typeof PosthogFeatureFlagName>;

enum POSTHOG_EXPERIMENT_NAME {
  NEW_INSERT = 'new-insert',
}

/** The keys of all active experiments defined in Posthog. Used for running Growth experiments. */
export const PosthogExperimentName = z.nativeEnum(POSTHOG_EXPERIMENT_NAME);
export type PosthogExperimentName = z.infer<typeof PosthogExperimentName>;

export const CONTROL_VARIANT_NAME = 'control';

// TODO fix, dangerous to check for equality based on array index
/** List all active Posthog experiments and each one's possible variant values */
export const POSTHOG_EXPERIMENTS: { [name in POSTHOG_EXPERIMENT_NAME]: string[] } = Object.freeze({
  [POSTHOG_EXPERIMENT_NAME.NEW_INSERT]: [CONTROL_VARIANT_NAME, 'new-insert'],
});
