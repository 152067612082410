/* eslint-disable @typescript-eslint/no-use-before-define */
// Adapted from https://github.com/Flyrell/axios-auth-refresh
// Fixed several typing errors, cleaned up code.

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { isDefined } from './parseUtils';

// Types

export interface AxiosAuthRefreshOptions {
  instance?: AxiosInstance;
  statusCodes?: Array<number>;
  skipWhileRefreshing?: boolean;
}

export interface AxiosAuthRefreshCache {
  skipInstances: AxiosInstance[];
  refreshCall: Promise<any> | undefined;
  requestQueueInterceptorId: number | undefined;
}

export interface AxiosAuthRefreshRequestConfig extends AxiosRequestConfig {
  skipAuthRefresh?: boolean;
}

// Constants

const defaults: AxiosAuthRefreshOptions = {
  statusCodes: [401],
  instance: undefined,
  skipWhileRefreshing: true,
};

const cache: AxiosAuthRefreshCache = {
  skipInstances: [],
  refreshCall: undefined,
  requestQueueInterceptorId: undefined,
};

/**
 * Creates an authentication refresh interceptor that binds to any error response.
 * If the response status code is one of the options.statusCodes, interceptor calls the refreshAuthCall
 * which must return a Promise. While refreshAuthCall is running, all the new requests are intercepted and are waiting
 * for the refresh call to resolve. While running the refreshing call, instance provided is marked as a paused instance
 * which indicates the interceptor to not intercept any responses from it. This is because you'd otherwise need to mark
 * the specific requests you make by yourself in order to make sure it's not intercepted. This behavior can be
 * turned off, but use it with caution as you need to mark the requests with `skipAuthRefresh` flag yourself in order to
 * not run into interceptors loop
 * @param {AxiosInstance} instance
 * @param {(error: any) => Promise<AxiosPromise>} refreshAuthCall - refresh token call which must return a Promise
 * @param {AxiosAuthRefreshOptions} options - options for the interceptor @see defaultOptions
 * @return {number} - interceptor id (in case you want to eject it manually)
 */
export default function createAuthRefreshInterceptor(
  instance: AxiosInstance,
  refreshAuthCall: (error: any) => Promise<any>,
  options: AxiosAuthRefreshOptions = {}
): number {
  return instance.interceptors.response.use(
    (res: AxiosResponse) => res,
    (error: any) => {
      // Rewrite default config
      options = mergeConfigs(options, defaults);

      // Reject promise if the error status is not in options.ports
      if (!shouldInterceptError(error, options, instance, cache)) {
        return Promise.reject(error);
      }

      // If refresh call does not exist, create one
      cache.skipInstances.push(instance);
      const refreshing = createRefreshCall(error, refreshAuthCall, cache);

      // Create interceptor that will bind all the others requests until refreshAuthCall is resolved
      createRequestQueueInterceptor(instance, cache);

      return refreshing
        .finally(() => {
          cache.refreshCall = undefined;
          if (isDefined(cache.requestQueueInterceptorId)) {
            instance.interceptors.request.eject(cache.requestQueueInterceptorId);
          }
          cache.skipInstances = cache.skipInstances.filter(inst => inst !== inst);
          cache.requestQueueInterceptorId = undefined;
        })
        .catch(err => {
          return Promise.reject(err);
        })
        .then(() => {
          error.config.skipAuthRefresh = true;
          return axios(error.response.config);
        });
    }
  );
}

/**
 * Merges two config objects (master rewrites slave)
 */
export function mergeConfigs(master: AxiosAuthRefreshOptions, def: AxiosAuthRefreshOptions): AxiosAuthRefreshOptions {
  return { ...def, ...master };
}

/**
 * Returns TRUE: when error.response.status is contained in options.statusCodes
 * Returns FALSE: when error or error.response doesn't exist or options.statusCodes doesn't include response status
 */
export function shouldInterceptError(
  error: any,
  options: AxiosAuthRefreshOptions,
  instance: AxiosInstance,
  cache2: AxiosAuthRefreshCache
): boolean {
  // todo: A bit worried about fixing the falsey stuff here, so leaving it for now.
  /* eslint-disable @typescript-eslint/strict-boolean-expressions */
  return (
    error &&
    !error.config?.skipAuthRefresh &&
    error.response &&
    (options.statusCodes ?? []).includes(+error.response.status) &&
    !(options.skipWhileRefreshing && cache2.skipInstances.includes(instance))
  );
  /* eslint-enable @typescript-eslint/strict-boolean-expressions */
}

/**
 * Creates refresh call if it does not exist or returns the existing one
 */
function createRefreshCall(error: any, fn: (error: any) => Promise<any>, cache2: AxiosAuthRefreshCache): Promise<any> {
  if (!cache2.refreshCall) {
    cache2.refreshCall = fn(error);
    if (typeof cache2.refreshCall.then !== 'function') {
      console.warn('axios-auth-refresh requires `refreshTokenCall` to return a promise.');
      return Promise.reject();
    }
  }
  return cache2.refreshCall;
}

/**
 * Creates refresh call if it does not exist or returns the existing one
 */
function createRequestQueueInterceptor(instance: AxiosInstance, cache2: AxiosAuthRefreshCache): number {
  if (typeof cache2.requestQueueInterceptorId === 'undefined') {
    cache2.requestQueueInterceptorId = instance.interceptors.request.use(request => {
      return (cache2.refreshCall ?? Promise.reject())
        .catch(() => {
          // eslint-disable-next-line @typescript-eslint/no-throw-literal
          throw new axios.Cancel('Request call failed');
        })
        .then(() => request);
    });
  }
  return cache2.requestQueueInterceptorId;
}
