import useEffectOnce from 'components/hooks/useEffectOnce';
import { CardLayout } from 'components/layouts/partials/CardLayout';
import Loader from 'components/loaders';
import apiClient, { addLoginCallback } from 'components/services/api/apiClient';
import { logout } from 'components/services/api/session';
import Router from 'next/router';
import posthog from 'posthog-js';
import React, { useState } from 'react';
import { COUNTRY_LANGUAGE_LOCALE } from 'util/i18n';
import { noop } from 'util/miscUtils';
import { isDefined } from 'util/parseUtils';
import { StripeLocale } from 'util/stripeParseUtils';

export type Me = {
  email: string;
  firstName: string;
  lastName: string;
  stripeCustomerId: string | null;
  phoneNumber: string;
  hasMonthlyReport: boolean;
  sensorVendor: string;
  preferredLanguageLocale: COUNTRY_LANGUAGE_LOCALE;
  stripeLocale: StripeLocale;
  eligibleForMetabolicPanel: boolean;
  analyticsId: string;
  pendingPhlebotomyVisitReferenceId: string | null;
  dob: string | null;
  gender: string | null;
};

const MeContext = React.createContext<Me>({} as Me);

export const MeProvider: React.FC<{ children: React.ReactNode; showLoading?: boolean }> = ({ children, showLoading = true }) => {
  const [me, setMe] = useState<Me>({} as Me);
  const [loading, setLoading] = useState(true);

  function logoutUser() {
    logout().finally(() => {
      posthog.reset();
      void Router.push('/home/login').finally(() => {
        setLoading(false);
      });
    });
  }

  useEffectOnce(() => {
    const fetchMe = async () => {
      try {
        const result = await apiClient.get('/api/user/me');
        if (!isDefined(result.data)) {
          logoutUser();
          return;
        }

        if (isDefined(result.data.analyticsId)) posthog.identify(result.data.analyticsId, { email: result.data.email });

        setMe(result.data);
        setLoading(false);
      } catch {
        logoutUser();
      }
    };

    fetchMe().finally(noop);

    // Add listener to refetch me object after successful login
    addLoginCallback(fetchMe);
  });

  return (
    <MeContext.Provider value={me}>
      {loading && showLoading ? (
        <CardLayout className="h-screen flex align-middle" color="black">
          <Loader centered className="text-cyan-green" />
        </CardLayout>
      ) : (
        children
      )}
    </MeContext.Provider>
  );
};

const useMe = () => React.useContext(MeContext);

export default useMe;
