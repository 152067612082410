import { getCookie } from 'cookies-next';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import env from 'util/env';
import isBrowser from 'util/isBrowser';

const POSTHOG_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_PUBLISHABLE_KEY ?? '';

/**
 * @see https://posthog.com/docs/integrate/client/js#config
 *
 * Oddities to be aware of in Posthog debug mode:
 *  - Debug mode won't work with many ad blockers (including Brave browser). Try Google Chrome with ad blockers disabled.
 *  - if you update the debug mode settings (i.e. if you follow the above^^ instructions), you'll need to restart your server +
 */

function initPosthog() {
  const flags = getCookie('bootstrapData');

  let bootstrapData = {};
  if (flags !== null && flags !== undefined) {
    bootstrapData = JSON.parse(flags);
  }

  if (!env.isProdNode) {
    // @see {@link https://posthog.com/tutorials/nextjs-bootstrap-flags}
    console.log('bootstraping Posthog feature flags:', bootstrapData);
  }

  posthog.init(POSTHOG_API_KEY, {
    api_host: `${document.location.origin}/ingest`,
    // NOTE temporarily commenting out to skip bootstraping feature flags
    // bootstrap: bootstrapData,
    disable_session_recording: !env.isProdNode,
    loaded: _posthog => {
      if (!env.isProdNode) _posthog.debug();
    },
    opt_in_site_apps: true, // early access features
    ui_host: 'https://app.posthog.com',
  });
}

if (isBrowser) initPosthog();

export default function PHProvider({ children }) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
