// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop(_param?: any) {}

export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};

// This is not perfect, but "good enough"
export function detectMobile(agent: string) {
  const mobileBrowsers = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];

  return mobileBrowsers.some(browser => {
    return browser.exec(agent);
  });
}

export function detectIOS(agent: string) {
  const mobileBrowsers = [/iPhone/i, /iPad/i];

  return mobileBrowsers.some(browser => {
    return browser.exec(agent);
  });
}

export function detectAndroid(agent: string) {
  const mobileBrowsers = [/Android/i];

  return mobileBrowsers.some(browser => {
    return browser.exec(agent);
  });
}

export function retryPromise<T>(fn: () => Promise<T>, numRetries: number, coolOffIntervalMs: number) {
  return new Promise<T>((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        if (numRetries === 1) {
          reject(error);
          return;
        }
        setTimeout(() => {
          retryPromise(fn, numRetries - 1, coolOffIntervalMs).then(resolve, reject);
        }, coolOffIntervalMs);
      });
  });
}

export function getLevelsPhone(): string {
  return '(408) 716-3771';
}

export const NEW_COMMERCE_LEVELS_PHONE = '+14087163771';

export const PHLEBOTOMY_PRICE = 149;
