import { differenceInDays, subDays } from 'date-fns';
import { EventEmitter } from 'events';
import { mean } from 'mathjs';
import { clock } from 'util/dates';
import env from 'util/env';
import { isDefined } from 'util/parseUtils';

/**
 * Get start and end time from input range. If range is not defined set default values
 * Default values:
 * - startTime: 1 day ago from now
 * - endTime: now
 * @param range [optional] a 2 length array with timestamp values. Example [start, end]
 * @returns the range values in array format (2 length) parsed to Date(). Example: [start, end]
 */
export const getTimesFromRange = (range: [number, number] | undefined, maxRangeInDays: number, defaultStart?: Date): [Date, Date] => {
  let startTime = defaultStart ?? subDays(clock.now, 1),
    endTime = clock.now;
  if (range && range.length === 2) {
    const [start, end] = range;
    startTime = new Date(start);
    endTime = new Date(end);
  }

  if (isDefined(maxRangeInDays) && differenceInDays(endTime, startTime) > maxRangeInDays) {
    startTime = subDays(endTime, maxRangeInDays);
  }

  return [startTime, endTime];
};

/**
 * This is run before the server is up, so do not import anything that assumes
 * the server is running (like config).
 */

export const randomString = (length = 22) => {
  const times = Math.ceil(length / 11);
  const buffer: string[] = [];
  for (let i = 0; i < times; i++) {
    buffer.push(Math.random().toString(36).substring(2, 15));
  }

  return buffer.join('').substring(0, length);
};

export const caller = (level = 1) => {
  const stack = new Error().stack ?? '';
  return stack.split('at ')[level + 1].trim();
};

export const serverOnly = () => {
  if ('browser' in process) {
    throw new Error(`Should only run server side. Failing. (${caller(2)})`);
  }
};

export function devNodeOnly<T>(fn: () => T): T | null {
  // node is running in dev mode
  if (!env.isProdNode) {
    try {
      return fn();
    } catch (err) {
      console.error('[devOnly] Error', err);
    }
  }
  return null;
}

export class PromiseTimeoutError extends Error {
  constructor(message) {
    super(message);
    this.name = 'PromiseTimeoutError';
  }
}
export function promiseTimeout<T>(ms: number, promise: Promise<T>): Promise<T> {
  // Create a promise that rejects in <ms> milliseconds
  const timeout = new Promise<T>((resolve, reject) => {
    const id = setTimeout(() => {
      clearTimeout(id);
      reject(new PromiseTimeoutError(`Timed out in ${ms}ms`));
    }, ms);
  });

  return Promise.race([promise, timeout]);
}

export class Lock {
  private locked: boolean;
  private eventEmitter: EventEmitter;
  constructor() {
    this.locked = false;
    this.eventEmitter = new EventEmitter();
    this.eventEmitter.setMaxListeners(32);
  }

  private acquire() {
    return new Promise(resolve => {
      if (!this.locked) {
        this.locked = true;
        return resolve(undefined);
      }

      const tryAcquire = () => {
        if (!this.locked) {
          this.locked = true;
          this.eventEmitter.removeListener('release', tryAcquire);
          return resolve(undefined);
        }
      };
      this.eventEmitter.on('release', tryAcquire);
    });
  }

  private release() {
    this.locked = false;
    setImmediate(() => this.eventEmitter.emit('release'));
  }

  isLocked() {
    return this.locked;
  }

  async use<T>(fn: () => Promise<T>) {
    try {
      await this.acquire();
      return await fn();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.release();
    }
  }
}

export class SemaphoreLock {
  public availableWorkers: number;
  private eventEmitter: EventEmitter;
  public waitingTasks: number;
  constructor(public readonly workersCount: number) {
    this.availableWorkers = workersCount;
    this.eventEmitter = new EventEmitter();
    this.eventEmitter.setMaxListeners(128);
    this.waitingTasks = 0;
  }

  private acquire() {
    return new Promise(resolve => {
      if (this.availableWorkers > 0) {
        this.availableWorkers -= 1;
        return resolve(undefined);
      }

      this.waitingTasks++;
      const tryAcquire = () => {
        if (this.availableWorkers > 0) {
          this.availableWorkers -= 1;
          this.waitingTasks--;
          this.eventEmitter.removeListener('release', tryAcquire);
          return resolve(undefined);
        }
      };
      this.eventEmitter.on('release', tryAcquire);
    });
  }

  hasAvailableWorkers() {
    return this.availableWorkers > 0;
  }

  private release() {
    this.availableWorkers += 1;
    setImmediate(() => this.eventEmitter.emit('release'));
  }

  async use<T>(fn: () => Promise<T>) {
    try {
      await this.acquire();
      return await fn();
    } catch (err) {
      return Promise.reject(err);
    } finally {
      this.release();
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-empty-function
export function noop(_param?: any) {}

export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };

/**
 * Rounds a value to a maximal number of digits.
 * @param digits Number of decimals to keep
 */
export function roundToDigits(value: number, digits: 0 | 1 | 2 | 3 | 4 | 5 | 6) {
  if (value < 1e6) {
    // For small values, use a sneaky trick that avoids floating-point errors.
    const rounded = Math.round(Number.parseFloat(`${value}e${digits}`));
    return Number.parseFloat(`${rounded}e-${digits}`);
  } else {
    return Math.round(value * Math.pow(10, digits)) / Math.pow(10, digits);
  }
}

export async function gotoSleep(time: number) {
  await new Promise(r => setTimeout(r, time));
}

export function toArrayBuffer(buffer: Buffer): ArrayBuffer {
  const ab = new ArrayBuffer(buffer.length);
  const view = new Uint8Array(ab);
  for (let i = 0; i < buffer.length; ++i) {
    view[i] = buffer[i];
  }
  return ab;
}

export function toBuffer(ab: Uint8Array): Buffer {
  const buffer = Buffer.alloc(ab.byteLength);
  for (let i = 0; i < buffer.length; ++i) {
    buffer[i] = ab[i];
  }
  return buffer;
}

export function tryToFormatPhoneNumber(phoneNumber: string): string | null {
  const digits = phoneNumber.replace(/\D/g, '');

  const [matches, areaCode, officeCode, lineNumber] = /(\d{3})(\d{3})(\d{4})$/.exec(digits) ?? [];
  if (isDefined(matches)) {
    return `(${areaCode}) ${officeCode}-${lineNumber}`;
  } else {
    return null;
  }
}

/**
 * calculate arithmetic mean of an array of values, ignoring null/undefined. If all values are null/undefined, return NaN.
 * (analog of numpy.nanmean or Matlab nanmean)
 * @param values
 */
export function nanmean(values: (number | null | undefined)[]): number {
  const validValues = values.filter(isDefined).filter(v => !isNaN(v));

  if (validValues.length === 0) {
    return NaN;
  }
  return mean(validValues);
}

/**
 * Checks if the given input value is a member of the given enum. This should work for any native TS
 * enum, and is designed particularly for enums with string values. _Technically_ this will also
 * work with regular TS objects where the values are primitives.
 *
 * @example Typical usage with a string enum:
 * ```ts
 * enum ProductSku {
 *  LIBRE_SKU = 'libre_sku',
 *  DEXCOM_SKU = 'dexcom_sku'
 * }
 *
 * const input = ProductSku.LIBRE_SKU
 * isMemberOf(input, ProductSku) // true!
 *
 * const input = 'libre_sku'
 * isMemberOf(input, ProductSku) // true!
 *
 * const input = 'some_sku'
 * isMemberOf(input, ProductSku) // false
 * ```
 */
export const isMemberOf = <Enum extends Record<string, any>>(value: unknown, enumType: Enum): value is Enum => {
  // Fail loudly if called with a value that isn't a primitive – utility is not designed for that
  const isNonPrimitiveValue = typeof value === 'function' || typeof value === 'object' || typeof value === 'symbol';
  if (isNonPrimitiveValue) {
    throw Error(`[isMemberOf] Type of input must be a primitive; type was found to be: ${typeof value}`);
  }

  // Extract values from enum or dictionary
  const values = Object.values({ ...enumType });
  // Finally test membership in array of values
  return values.includes(value as Enum);
};
