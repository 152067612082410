import { useRouter } from 'next/router';
import React, { createContext, useContext, useEffect, useState } from 'react';

interface HValidation {
  history: string[];
  setHistory(data: string[]): void;
}

// The purpose of this context is to have the previous route path to understand where is user navigates from
const RouterHistoryContext = createContext<HValidation>({} as HValidation);
export const RouterHistoryProvider: React.FC = ({ children }) => {
  const { asPath } = useRouter();
  const [history, setHistory] = useState<string[]>([]);

  useEffect(() => {
    setHistory(previous => [previous[previous.length - 1], asPath]);
  }, [asPath]);

  return (
    <RouterHistoryContext.Provider
      value={{
        history,
        setHistory,
      }}
    >
      {children}
    </RouterHistoryContext.Provider>
  );
};

export function useRouterHistory(): HValidation {
  return useContext(RouterHistoryContext);
}
