import { datadogLogs } from '@datadog/browser-logs';
import { CONTROL_VARIANT_NAME, PosthogExperimentName, PosthogFeatureFlagName } from '@lib/posthog/types';
import Loader from 'components/loaders';
import { posthog } from 'posthog-js';
import React, { useEffect, useState } from 'react';
import { isDefined, isTruthy } from 'util/parseUtils';

const POSTHOG_API_KEY = process.env.NEXT_PUBLIC_POSTHOG_PUBLISHABLE_KEY;

const FeatureFlagContext = React.createContext<Record<string, string | boolean> | undefined>(undefined);

type FeatureFlagProviderProps = {
  children: React.ReactNode;
};

function FeatureFlagProvider({ children }: FeatureFlagProviderProps) {
  const [featureFlags, setFeatureFlags] = useState<Record<string, string | boolean> | undefined>();
  const [loading, setLoading] = useState(isTruthy(POSTHOG_API_KEY));

  useEffect(() => {
    // first callback value is an array of all feature flags, second is an object with all feature flags and each one's value
    posthog.onFeatureFlags((_flags, variants) => {
      datadogLogs.logger.info('TEMP: feature flags are', variants);
      setFeatureFlags(variants);
      setLoading(false);
    });
  }, []);

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {/*
      TODO: not sure this is the best approach for perceived speed. I am sure there is something valuable we can put on the screen that is static for all users.
      I think if a component is dependant on an experiment we should let it be responsible for showing a loading state if it needs to.
       */}
      {loading ? (
        <div className="h-[calc(100vh-3.75rem)] w-full flex items-center self-center justify-center">
          <Loader />
        </div>
      ) : (
        children
      )}
    </FeatureFlagContext.Provider>
  );
}

/**
 * Use this hook to retrieve feature flags and experiments from Posthog.
 *  - @see https://posthog.com/manual/experimentation
 *  - @see https://www.notion.so/levelshealth/draft-Growth-Experiments-9d9851802bb34ab3a412aad4f6d6813b?pvs=4
 */
function useFeatureFlag(key: PosthogFeatureFlagName | PosthogExperimentName) {
  const context = React.useContext(FeatureFlagContext);

  if (!isDefined(context) && isTruthy(POSTHOG_API_KEY)) {
    throw new Error('useFeatureFlag must be used within a FeatureFlagProvider');
  }

  const variant = context?.[key];
  const isExperiment = Object.values(PosthogExperimentName.enum).includes(key as unknown as PosthogExperimentName);

  // Monitor will alert in this case: https://app.datadoghq.com/monitors/125777164
  // we don't want to throw an error if we lose connection to Posthog, but if we see this,
  // we either lost connection to Posthog or prematurely deleted a feature flag from Posthog.
  // This is an issue because users could be seeing unexpected UIs / states as a result.
  if (!isDefined(variant)) {
    datadogLogs.logger.error('No feature flag found in Posthog given key', { key, context });
  }

  return {
    featureFlagName: key,
    isExperiment,
    // default to control/false if variant is not defined
    variant: isExperiment ? variant ?? CONTROL_VARIANT_NAME : variant ?? false,
    isControl: isExperiment ? variant === CONTROL_VARIANT_NAME || !isDefined(variant) : false,
  };
}

export { useFeatureFlag, FeatureFlagProvider };
