/**
 * Do not import anything. This should be static.
 */

function aptibleEnvConfig() {
  if (process.env.NODE_ENV === 'production' && typeof process.env.APTIBLE_APP_HREF !== 'undefined') {
    // See https://www.aptible.com/documentation/deploy/reference/containers/environment.html
    return {
      APTIBLE_PROCESS_TYPE: process.env.APTIBLE_PROCESS_TYPE,
      APTIBLE_PROCESS_INDEX: process.env.APTIBLE_PROCESS_INDEX,
      APTIBLE_CONTAINER_SIZE: process.env.APTIBLE_CONTAINER_SIZE,
      APTIBLE_LAYER: process.env.APTIBLE_LAYER,
      APTIBLE_GIT_REF: process.env.APTIBLE_GIT_REF,
      APTIBLE_ORGANIZATION_HREF: process.env.APTIBLE_ORGANIZATION_HREF,
      APTIBLE_APP_HREF: process.env.APTIBLE_APP_HREF,
      APTIBLE_SERVICE_HREF: process.env.APTIBLE_SERVICE_HREF,
      APTIBLE_RELEASE_HREF: process.env.APTIBLE_RELEASE_HREF,
      APTIBLE_RESOURCE_HREF: process.env.APTIBLE_RESOURCE_HREF,
      APTIBLE_ALLOCATION: process.env.APTIBLE_ALLOCATION,
    };
  } else {
    return null;
  }
}

const aptibleConfig = aptibleEnvConfig();

export const DEV_FALLBACK_REF = 'dev';

const env = {
  isProdNode: process.env.NODE_ENV === 'production',
  isTestNode: process.env.NODE_ENV === 'test',
  processEnv: process.env,
  isInAptible: typeof process.env.APTIBLE_APP_HREF !== 'undefined',
  aptible: aptibleConfig,
  version: aptibleConfig?.APTIBLE_GIT_REF?.substring(0, 7) ?? DEV_FALLBACK_REF,
  isWorker: aptibleConfig?.APTIBLE_PROCESS_TYPE === 'worker', // process types defined in Procfile
};

export function throwErrorIfNotWorkerEnv(errMsg = 'Trying to execute code intended for worker env only') {
  if (env.isInAptible && !env.isWorker) {
    throw new Error(errMsg);
  }
}

export default env;
