import Script from 'next/script';
import env from 'util/env';

interface GoogleTagManagerScriptProps {
  scriptId: string;
}

export const GoogleTagManagerScript = ({ scriptId }: GoogleTagManagerScriptProps) => {
  return env.isProdNode ? ProductionScript(scriptId) : StagingScript(scriptId);
};

function ProductionScript(scriptId: string) {
  return (
    <>
      <Script id={scriptId}>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://tags.levelshealth.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-PWWWX6B');`}
      </Script>
      {/* End Google Tag Manager */}
      {/* Google Tag Manager (noscript) */}
      <noscript>
        <iframe
          src={`https://tags.levelshealth.com/ns.html?id=$GTM-PWWWX6B}`}
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
    </>
  );
}

function StagingScript(scriptId: string) {
  return (
    <>
      {/* <!-- Google Tag Manager --> */}
      <Script id={scriptId}>
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://tags.levelshealth.com/gtm.js?id='+i+dl+ '&gtm_auth=ufwFbEKOQ9bSnxqhrdXa4w&gtm_preview=env-27&gtm_cookies_win=x';f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-PWWWX6B');`}
      </Script>
      {/* <!-- End Google Tag Manager --> */}
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript>
        <iframe
          src="https://tags.levelshealth.com/ns.html?id=GTM-PWWWX6B&gtm_auth=ufwFbEKOQ9bSnxqhrdXa4w&gtm_preview=env-27&gtm_cookies_win=x"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}
        ></iframe>
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>
  );
}
