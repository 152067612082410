import { FC } from 'react';
import { Toaster } from 'react-hot-toast';

import { FeatureFlagProvider } from '../../hooks/useFeatureFlag';
import { MeProvider } from '../../hooks/useMe';
import { RouterHistoryProvider } from '../../hooks/useRouterHistory';

import styleVariable from '../../../styles/variables.module.scss';

const Layout: FC = ({ children }) => {
  return (
    <>
      <RouterHistoryProvider>
        <FeatureFlagProvider>
          <MeProvider>
            {children}
            <Toaster
              toastOptions={{
                success: {
                  iconTheme: {
                    primary: styleVariable.darkGreen,
                    secondary: 'white',
                  },
                },
              }}
            />
          </MeProvider>
        </FeatureFlagProvider>
      </RouterHistoryProvider>
    </>
  );
};

export default Layout;
