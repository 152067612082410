import { noop } from '@lib/util';
import { AxiosInstance } from 'axios';
import posthog from 'posthog-js';
import { isDefined, isTruthy, stringOrNull } from 'util/parseUtils';
import apiClient, { getUserId, logInWithRefreshToken, logOutClearingRefreshToken } from './apiClient';

export const checkIfUserNeedsToLogin = (email?: string, requiredRole?: string, client?: AxiosInstance) => {
  return (client ?? apiClient)
    .post('/api/user/login', { email: email, dryCheck: true, role: requiredRole })
    .then(resp => {
      return isTruthy(resp.data['needs_to_auth']);
    })
    .catch(() => {
      return true;
    });
};

export function loginEmailPass(email: string, password: string): Promise<{ success: boolean; postLoginUrl?: string }> {
  return apiClient
    .post('/api/user/login', { email: email, password: password, setSession: true })
    .then(resp => {
      const refreshToken = stringOrNull(resp.data['refresh']);
      const userId = stringOrNull(resp.data['userId']);
      const postLoginUrl = stringOrNull(resp.data['postLoginUrl']) ?? undefined;
      if (isDefined(refreshToken) && isDefined(userId)) {
        logInWithRefreshToken(userId, refreshToken, true);
        return { success: true, postLoginUrl };
      } else {
        return { success: false };
      }
    })
    .catch(() => {
      return Promise.resolve({ success: false });
    });
}

// todo: it's best to not have an endpoint that side effects like this.
// make it require a two-way shake
export const logout = (resetPosthog = true) => {
  logOutClearingRefreshToken();
  if (resetPosthog) {
    posthog.reset();
  }
  return apiClient.post('/api/user/logout').then(() => {
    return;
  });
};

export const safeLogoutAndReloadPage = () => {
  logout()
    .catch(noop)
    .finally(() => window.location.reload());
};

export const setPassword = (email: string, password: string, userId?: string | null) => {
  const knownUserId = userId ?? getUserId();
  if (isDefined(knownUserId)) {
    return apiClient.post('/api/user/set-password', { email, password, userId: knownUserId });
  } else {
    return Promise.reject('no_active_session');
  }
};

export const initiateForgotPassword = (email: string) => {
  return apiClient.post('/api/user/forgot-password', { email }).then(resp => {
    return Boolean(resp.data?.success);
  });
};

export const setForgottenPassword = (email: string, password: string, verificationCode: string) => {
  return apiClient.post('/api/user/forgot-password', { email, password, verificationCode }).then(resp => {
    const refreshToken = stringOrNull(resp.data['refresh']);
    const userId = stringOrNull(resp.data['userId']);
    if (isDefined(refreshToken) && isDefined(userId)) {
      logInWithRefreshToken(userId, refreshToken, true);
      return { success: true };
    } else {
      return { success: false };
    }
  });
};
